/*
 * A Side
 */

// Variables

var $asideBtnOpen = $('.js_aside-btn'),
	$asideBtnClose = $('.js_aside-close'),
	$aside = $('.main-aside');
	$asideContent = $aside.find('.main-aside-content');

// Functions

function closeASide () {
	$aside.removeClass('is-open');
	$html.removeClass('aside-is-active');
	setTimeout(function() {
		$asideContent.html('');
	}, 500);
}

function openASide ( content, type, $this ) {
	if ( typeof content == 'undefined' ) {
		return false;
	}

	if ( (typeof type != 'undefined' && type == 'ajax') || (typeof content == 'string' && content.indexOf('http') >= 0) ) {
		$asideContent.load( content );
		$aside.addClass('is-open');
		$html.addClass('aside-is-active');
	} else {
		try {
			if ( typeof content.context != 'undefined' ) {
				content = $(content);
			}
			
			$asideContent.html( content.html() );
			$aside.addClass('is-open');
			$html.addClass('aside-is-active');
		} catch(errorMessage) {
			if ( typeof $this != 'undefined' ) {
				$this.addClass('has-error');
			}
		}
	}

	if ( typeof $this != 'undefined' ) {
		setTimeout(function() {
			$this.removeClass('has-error');
		}, 1000);
	}
}

// Events

$asideBtnOpen.click(function () {
	var $this = $(this),
		$content = $this.attr('data-aside-content'),
		type = $this.attr('data-aside-type');

	openASide( $content, type, $this );
});

$asideBtnClose.click(closeASide);
